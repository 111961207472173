import Service from "../Service";
import Vue from "vue";
const resource = "sleCriteriaAdvance/";

export default {
    pagination(parameters, requestID) {
        return Service.post(resource + "pagination", parameters, {
            params: { requestID },
        });
    },

    save(Cra, requestID) {
        return Service.post(resource + "save", Cra, {
            params: { requestID }
        });
    },

     list(RequestID ){
        
    return Service.post(resource + "list",{},{
    params:{RequestID: RequestID}
  });
},
GetUrllist(){
     
  return Vue.prototype.$http.defaults.baseURL + "/sleCriteriaAdvance/list";
 
},

}