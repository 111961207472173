<template>
  <div>
    <v-row>
    <v-col lg="12">
     <criteriaadvance :isAdmin="true"  
     @rowSelected="rowSelected($event)" :height="200"> ></criteriaadvance>
     
      </v-col>
    </v-row>
  </div>
</template>

<script>
import criteriaadvance from './CriteriaAdvance.vue';

export default {
  components: {      
    criteriaadvance
    },

   data() {
    return {
      parentID: 0,
      craID:0, 
      craState:0,     
    };
  },
  methods: {
 
  },
};
</script>
