<template>
  
    <s-crud @returnObject='refresh($event)'
    no-border
    title="Criterios de Adelanto"
    :config="config"
    @save="save($event)"
    edit
    add
    @clearForm="clearForm()"
    @rowSelected="rowSelected($event)"
    remove    
    :filter="filter"
    search-input  
    ref="crud"
    >
      <template v-slot:filter>
      <v-col>
        <v-row justify="center">
          <v-col lg="8" >
          <v-card class="pa-2">
        
           <sselect-sun-cobranza clearable v-model="filter.Code" label="Criterios"></sselect-sun-cobranza>

            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </template>

   <template scope="props">
        <!--  <v-container class="pb-0">  -->
          
      <v-card-text class="pa-0" >
        <v-row justify="center">

          <v-col cols="4" class="s-col-form" justfy="center" style="margin: initial;"  lg="3">
           <v-card class="pa-2">
            <sselect-sun-cobranza clearable v-model="props.item.Code" label="Criterios"></sselect-sun-cobranza>
             </v-card>
          </v-col>

          <v-col cols="12" class="s-col-form" sm="3" md="3" lg="3" justfy="center" style="margin: initial;">
             <v-card class="pa-2">
            <s-text v-model="props.item.CraUnit" label="Unidad"></s-text>
             </v-card>
          </v-col>
           <v-col cols="12" class="s-col-form" sm="3" md="3" lg="3" justfy="center" style="margin: initial;">
             <v-card class="pa-2">
            <s-text v-model="props.item.CraQuantity" label="Cantidad"></s-text>
             </v-card>
          </v-col>
          <v-col cols="12" class="s-col-form" sm="3" md="3" lg="3">
             <v-card class="pa-2">
            <s-select-definition v-model="props.item.CraStatus" label="Estado" :def="1301"></s-select-definition>
          </v-card>
          </v-col>

        </v-row> 
       
      </v-card-text> 


          </template>
           <template v-slot:CraStatus="{ row }">
        <v-chip style="margin:0px" x-small :color="row.CraStatus == 1 ? 'success' : 'error'">
          {{ row.CraStatus == 1 ? "Activa" : "Inactiva" }}
        </v-chip>
      </template>

     <template v-slot:SecStatus="{ row }">
        <v-chip style="margin:0px" x-small :color="row.SecStatus == 'Eliminado' ? 'error' : 'success'">
            {{ row.SecStatus}}
        </v-chip>
      </template> 
      
     </s-crud>

</template>


<script>
import _service from "@/services/Sale/SleCriteriaAdvanceService";
import SselectSunCobranza from '../../../components/Sale/SselectSunCobranza.vue';
export default {
  name: "ServiceSleCriteriaAdvance",
  components: {SselectSunCobranza  },
  props: {
    code: { type: String, default: "" },
    parentID: { type: Number, default: 0 },
    addNoFunction: { type: Boolean, default: false },
  },

  data() {
    return {
      craID: 0,
      dialogEdit: false,
       ataID: 0,  
       itemsSelect: [],
       itemsFarm: [], 
       filter: { Code: ""},  
     };     
  },

  computed: {
    config() {
      return {
        model: {
          //RETORNAR PROPIEDAD COMPUTADA
          CraID: "ID",
          Name: "string",
          CraUnit: "string",
          SecStatus: "string",
          CraStatus: "status",
          }, 
         service: _service,
       headers:  this.$fun.getSecurity().IsLevelAdmin  
          ? [
              //Vista de tabla para admin
              { text: "ID", value: "CraID", sortable: false },
              { text: "Criterio", value: "Name", sortable: false },
              { text: "Unidad", value: "CraUnit", sortable: false }, 
              { text: "Cantidad", value: "CraQuantity", sortable: false }, 
               { text: "Estado", value: "CraStatus", sortable: false }, 
                     
            ]
          : [ 
                // CABECERA EN CASO NO SE LOGEE COMO ADMIN            
            { text: "ID", value: "CraID", sortable: false },
              { text: "Criterio", value: "Name", sortable: false },
              { text: "Unidad", value: "CraUnit", sortable: false }, 
              { text: "Cantidad", value: "CraQuantity", sortable: false }, 
               { text: "Estado", value: "CraStatus", sortable: false }, 
            ],
      };
    },

    isAdmin() {
      this.$fun.getSecurity().IsLevelAdmin;
    },
  },

  methods: {
    Initialize() {
      //DedID: this.DedID -- base de datos: campo actual
      this.filter = { Code: this.code };
    },

    edit(item) {
      this.dialogEdit = true;
    },

    rowSelected(rows) {
      if (rows.length > 0) {
        this.craID = rows[0].CraID;
        this.$emit("rowSelected", rows[0]);
        this.itemsSelect = rows[0];
      }
    },

    save(item) {
      if (item.Code.length == 0) {
        this.$fun.alert(
          "Debe ingresar una descripcion para la actividad",
          "warning"
        );
        return;
      }

        item.SecStatus=1
        item.save();          
      
    },    
  },
  watch: {
    
    code(){        
      this.Initialize();    
     
      },
  },
   created() {
    this.filter = { Code: "" };
  },
};
</script>
